<template>
  <div class="cityE5">
    <el-container>
      <el-header style="width: 100%; height: 30%;">
          <vheader class="hidden-sm-and-down"></vheader>
          <ivheadera class="hidden-md-and-up"></ivheadera>
          <div class="top-nav">
            <div class="cnts">
              <span></span>
              <p class="c1">NEWS</p>
              <p class="c2">动态</p>
            </div>
            <div class="w">
              <div class="ec-top-foots hidden-sm-and-down">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                  <el-breadcrumb-item to="/Accounts">城市号</el-breadcrumb-item>
                  <el-breadcrumb-item>城市新闻</el-breadcrumb-item>
                </el-breadcrumb>
              </div>
            </div>
          </div>
          <!-- <img class="hidden-sm-and-down" width="100%" src="../../assets/images/E/E5-topImg.png" alt="">
          <img class="hidden-md-and-up" width="100%" src="../../assets/images/E/E5-topImg-m.png" alt=""> -->
      </el-header>
      <el-main>
          <div class="ew">
              <div class="hle">{{xinwen.TITLE}}</div>
              <el-row type="flex" justify="space-between" class="wes">
                  <el-col :xs="22" :sm="15" :md="15" :lg="15" :xl="15" class="ft">
                      <div class="top-l">
                          <p>{{ xinwen.TIME }}</p>
                          <span> | </span>
                          <p>城市交流</p>
                          <span> | </span>
                          <p>消息来源: {{ xinwen.SEO描述 }}</p>
                          <div class="share">
                            <div class="social-share"></div>
                          </div>
                      </div>
                      <div class="new-cnts" v-html="msg"></div>
                      <div class="share">
                        <div class="social-share"></div>
                      </div>
                  </el-col>
                  <el-col :xs="22" :sm="8" :md="8" :lg="8" :xl="8" class="gt">
                      <div class="top-r">
                          相关信息
                      </div>
                      <div class="main-r">
                        <div class="main-it" @click="goto(item.SEECITYNUMMX_ID)" v-for="(item, index) in xins" :key="index">
                            <img :src="item.IMAGE" alt="">
                            <p>{{item.TITLE}}</p>
                        </div>
                        <gotop></gotop>
                    </div>
                  </el-col>
              </el-row>
          </div>
      </el-main>
      <el-footer>
        <vfooter class="hidden-sm-and-down"></vfooter>
        <ivfooter class="hidden-md-and-up"></ivfooter>
      </el-footer>
    </el-container>
  </div>
</template>
<script>
import Gotop from '../../components/gotop'
import { citynewwenbs, citynew } from '../../api'

export default {
  metaInfo () {
    return {
      title: this.xinwen.TITLE,
      meta: [{
        name: 'Description',
        content: this.xinwen.SEO描述
      },{
        name: 'keywords',
        content: this.xinwen.SEO关键字
      }]
    }
  },
  data () {
    return {
      xinwen: {},
      newid: {},
      // cityId: '',
      msg: {},
      xins: {}
    }
  },
  inject: ['reload'],
  created () {
    this.newid = this.$route.params.newId.replace('new', '')
    // this.xins = this.$route.query.name
    // console.log(2)
    // console.log(this.xins)
  },
  components: {
    Gotop
  },
  mounted () {
    citynew(this.newid).then(res => {
      this.xinwen = res.data[0]
      this.msg = res.data[0].CONTENT.replace(':8080', '')
      // console.log(res.data)
      // console.log(this.xinwen)
      // document.title = this.xinwen.TITLE
      // $('#meta1').attr('content',this.xinwen.TITLE)
      citynewwenbs(res.data[0].SEECITYNUM_ID).then(res1 => {
        // console.log(res1)
        let arr = []
        for (let i = 0; i < res1.data.length; i++) {
          if (res1.data[i].SEECITYNUMMX_ID != res.data[0].SEECITYNUMMX_ID) {
            arr.push(res1.data[i])
          }
        }
        let arrNew = []
        for (let i = 0; i < 3; i++) {
          if (arr.length != 0) {
            var _num = Math.floor(Math.random() * arr.length)
            var mm = arr[_num]
            arr.splice(_num, 1)
            arrNew.push(mm)
          }
        }
        this.xins = arrNew
      })
      var config = {
        url: window.location.href, // 网址，默认使用 window.location.href
        source: '', // 来源（QQ空间会用到）, 默认读取head标签：<meta name="site" content="http://overtrue" />
        title: this.xinwen.TITLE, // 标题，默认读取 document.title 或者 <meta name="title" content="share.js" />
        description: '敬请关注CityPlus趣活动平台，发布精彩活动。', // 描述, 默认读取head标签：<meta name="description" content="PHP弱类型的实现原理分析" />
        image: '/FH-WEB/uploadFiles/uploadImgs/20190829/adf551cfdd994f729a0f53e248d9b76c.jpg', // 图片, 默认取网页中第一个img标签
        sites: ['weibo', 'qq', 'wechat'], // 启用的站点
        // disabled: ['google', 'facebook', 'twitter'], // 禁用的站点
        wechatQrcodeTitle: '微信扫一扫：分享', // 微信二维码提示文字
        wechatQrcodeHelper: '<p>微信里点“发现”，扫一下</p><p>二维码便可将本文分享至朋友圈。</p>'
      }
      window.socialShare('.social-share', config)
    })
  },
  beforeDestroy () {
    // document.title = 'CityPlus'
  },
  methods: {
    goto (id) {
      let newId = 'new' + id
      this.$router.push({ name: `cityE5`, params: { newId: newId } })
      this.reload()
    }
  }
}
</script>

<style scoped>
.el-header {
    padding: 0;
}
.el-main {
    margin-top: 0;
}
.bg-b {
    display: none;
}
.bg-a {
    width: 100%;
}
.ew {
    max-width: 1154px;
    margin: auto;
}
.wes {
    display: flex;
    flex-wrap: wrap;
}
.ft {
    flex: 7;
    margin-right: 1.3rem;
}
.ge {
    flex: 2;
}
.top-l {
    border-bottom: 1px solid rgba(15,183,209,1);
    margin-bottom: 0.6rem;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.25rem;
    color:rgba(158,158,158,1);
}
.top-l p {
    float: left;
    padding-left: 5px;
}
.top-l span {
    float: left;
    margin: 0 0.2rem;
}
.top-r {
    height: 0.8rem;
    line-height: 0.8rem;
    text-align: left;
    margin-bottom: 0.6rem;
    border-bottom: 1px solid rgba(15,183,209,1);
}
.hle {
    max-width: 510px;
    font-size: 0.55rem;
    color:rgba(15,183,209,1);
    text-align: left;
    margin-top: 0.8rem;
    margin-bottom: 0.2rem;
}
.gt .main-r .main-it {
    margin-bottom: 0.5rem;
    text-align: left;
}
.gt .main-r .main-it img {
    width: 100%;
}
.gt .main-r .main-it p {
    padding-top: 0.1rem;
}
@media screen and (max-width:769px){
    .gt {
      flex: 100%;
    }
}
</style>

<style lang="stylus">
  .cityE5{
    .main-l p {
        text-indent: 2em;
        text-align: left;
        margin-bottom: 0.6rem;
        font-size: 0.3rem;
        line-height: 0.5rem;
    }
    .main-img {
        margin-top: 0.5rem;
    }
    .main-img p {
        margin-top: 0.3rem;
        text-align: left;
        text-indent: 2em;
        font-size: 0.3rem;
        margin-bottom: 0.6rem;
    }
    @media screen and (max-width:769px){
        .ft {
            flex: 100%;
            margin: 0;
        }
        .ups {
            display: none;
        }
        .up-ss {
            margin: 0 0.2rem;
        }
        .main-l p {
            line-height: 0.6rem;
        }
        .main-img p {
            text-align: center;
            line-height: 0.7rem;
            margin-bottom: 0.9rem;
        }
        .top-r {
            font-size: 0.5rem;
            border-bottom: none;
            border-top: 1px solid rgba(15,183,209,1);
            padding-top: 0.8rem;
            height: 1.3rem;
        }
        .main-r {
            margin: 0.5rem;
        }
    }
  }
/* .txt {
    text-align: left;
    font-size: 0.3rem;
    border-bottom: 1px solid rgba(15,183,209,1);
}
.txt p {
    text-indent: 2em;
    line-height: 0.5rem;
    color:#464646;
}
.txt h3 {
    margin: 0.7rem 0;
    text-indent: 2em;
} */
</style>
<style scoped lang="stylus">
  .cityE5{
    .top-nav{
      position relative
      width 100%
      height 9.0555rem
      background url(../../assets/images/E/E5-topImg.png) no-repeat center / cover
      .cnts{
        position absolute
        top 40%
        left 1.2rem
        color #fff
        text-align left
        span{
          display block
          width 80%
          height 2px
          background #fff
          margin-left .2rem
        }
        .c1{
          font-size 0.8888rem
          font-weight bold
          font-family HYQiHeiY1-NEW
          padding-top .3rem
        }
        .c2{
          font-size 0.6296rem
          font-family HYQiHeiY1
        }
      }
      .w{
        position relative
        height 100%
        .ec-top-foots {
          position: absolute;
          bottom: 0;
          left: .3rem;
          background: rgba(0, 182, 205,1);
          min-width: 6.5rem;
          height: 0.78rem;
          align-items: center;
          .el-breadcrumb{
            line-height: 0.78rem;
            padding-left: 10px;
          }
        }
      }
    }
    .ew{
      padding-bottom .6rem
      .hle{
        max-width 14.2rem
        font-weight bold
      }
    }
    .ft{
      margin 0
      .new-cnts{
        padding-bottom .6rem
        border-bottom 1px solid #0fb7d1
      }
      .top-l{
      }
      .share{
        display inline-block
        float right
      }
    }
    .gt{
      padding-left 1.4444rem
      .top-r{
        font-size 0.2592rem
      }
      .main-r{
        .main-it{
          cursor pointer
          p{
            font-size 0.2592rem
          }
          img{
            width 100%
            height 3.7rem
            object-fit cover
          }
        }
      }
    }
  }
  @media screen and (max-width:992px){
    .cityE5{
      .share{
        display none !important
      }
      .top-nav{
        width 100%
        height 9rem
        background url(../../assets/images/E/E5-topImg-m.png) no-repeat center 0 / cover
        .cnts{
          left .2rem
        }
      }
      .ew{
        .hle{
          max-width 100%
          font-size 0.5185rem
          margin-top .2rem
        }
      }
      .ft{
        .new-cnts{
          margin-top 0.8rem
        }
        .top-l{
          p{
            font-size 0.3703rem
          }
        }
      }
      .gt{
        padding 0
        .top-r{
          border none
          font-size 0.5555rem
          padding-top 0.9259rem
          height auto
          line-height initial
          margin 0
        }
        .main-r{
          margin .2rem
          .main-it{
            display flex
            align-items center
            justify-content center
            img{
              width 50%
              height 2.8rem
            }
            p{
              width 50%
              padding 0
              color #6a6969
              font-size 0.4444rem
              padding-left .4rem
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
            }
          }
        }
      }
    }
  }
</style>
<style lang="stylus">
  .cityE5{
    span{
      font-weight: inherit;
    }
    .el-main{
      overflow initial
      padding .3rem
    }
    .ec-top-foots .el-breadcrumb__separator{
      color #fff
    }
    .ec-top-foots .el-breadcrumb__inner{
      color #fff
      &.is-link{
        &:hover{
          color #fff600
        }
      }
    }
    .ft{
      .new-cnts{
        img{
          display block
          margin 0 auto
          max-width 100%
        }
        // .cntssource{
        //   text-align left
        //   text-indent 1.2em
        //   font-size 0.3703rem
        //   margin-top 0.1851rem
        //   font-weight bold
        // }
      }
    }
    .ft{
      .social-share{
        a{
          color #9e9e9e
          border none
          transition all .2s
          &:hover{
            background transparent
            &.icon-weibo{
              color #ff763b
            }
            &.icon-qq{
              color #56b6e7
            }
            &.icon-wechat{
              color #7bc549
            }
          }
        }
      }
    }
  }
</style>
